import { createFeatureSelector, createReducer, on } from '@ngrx/store';

import { updateAnalyticsProperties } from '@app/core/analytics.actions';

export interface AnalyticsState {
  flow?: string;
  membership_status?: string;
  om_membership_type?: string;
  member_age?: number;
  service_area?: string;
  b2b_company_name?: string;
  switched_account?: boolean;
  principal_user_patient_id?: string;
  flow_version?: string;
}

export const initialState: AnalyticsState = {
  flow: '',
  switched_account: false,
};

export const reducer = createReducer(
  initialState,
  on(updateAnalyticsProperties, (state, action) => ({
    ...state,
    ...action.updates,
  })),
);

export const getAnalyticsState = createFeatureSelector<AnalyticsState>('analytics');
