import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of as observableOf, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DropOffClaimCodeGraphqlService } from '@app/registration/drop-off-claim-code-graphql.service';
import { LoginWithAmazonRegistrationGraphqlService } from '@app/registration/login-with-amazon-registration-graphql.service';
import { CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-params';
import { User } from '@app/shared/user';

import { AuthService } from './auth.service';
import { HasClaimedAccessKeyGraphqlService } from './has-claimed-access-key-graphql.service';
import { LinksService } from './links.service';
import { Membership } from './membership';
import { UserService } from './user.service';

@Injectable()
export class RegistrationCompleteGuardService implements CanActivate {
  private desiredRoute: string;
  private readonly storage: Storage | null;

  constructor(
    private authService: AuthService,
    private dropOffClaimCodeGraphqlService: DropOffClaimCodeGraphqlService,
    private links: LinksService,
    private userService: UserService,
    private router: Router,
    private loginWithAmazonRegistrationGraphqlService: LoginWithAmazonRegistrationGraphqlService,
    private hasClaimedAccessKeyGraphqlService: HasClaimedAccessKeyGraphqlService,
  ) {
    this.storage = sessionStorage;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    this.desiredRoute = state.url.split('?')[0];

    return this.authService.isLoggedIn$().pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.runRegistrationCheck(route);
        } else if (!isLoggedIn && this.isLegacyOrEnterpriseRegistration(this.desiredRoute)) {
          return observableOf(true);
        } else {
          return observableOf(false);
        }
      }),
    );
  }

  private runRegistrationCheck(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    return this.userService.getUser(true).pipe(
      switchMap((patient: User) => {
        if (this.isEnterpriseReg(this.desiredRoute) || this.isOnboarding(this.desiredRoute)) {
          return observableOf(true);
        } else if (this.isLegacyRegistration(this.desiredRoute)) {
          return observableOf(this.canActivateLegacyRegistration(patient));
        } else if (!patient.isRegComplete) {
          if (patient.membershipPlanType === 'MedicareBasicPlan') {
            return observableOf(true);
          }
          return this.handleIncompleteRegistrationRouting(patient, route);
        }

        return observableOf(true);
      }),
      catchError(_error => {
        this.authService.goLogin();
        return observableOf(false);
      }),
    );
  }

  private canActivateLegacyRegistration(patient: User): boolean {
    if (patient.isRegComplete) {
      this.router.navigateByUrl(this.links.home);
      return false;
    }

    return true;
  }

  private handleIncompleteRegistrationRouting(
    patient: User,
    route: ActivatedRouteSnapshot,
  ): Observable<UrlTree | false> {
    return combineLatest([
      this.isLoginWithAmazonRegistration(),
      this.findClaimCode(route),
      this.hasClaimedChartKey(),
    ]).pipe(
      map(([isLwaReg, claimCode, hasClaimedKey]) => {
        if (hasClaimedKey) {
          return this.router.parseUrl(this.links.chartClaimingRestart);
        } else if (isLwaReg || (Membership.hasLimitedAccessPlanType(patient.membershipPlanType) && claimCode)) {
          return this.router.createUrlTree([this.links.registrationPaidLoginWithAmazon], {
            queryParams: { claim_code: claimCode },
          });
        } else if (patient.isExpeditedEnrollmentUser) {
          return this.router.parseUrl(this.links.registrationExpedited);
        } else if (Membership.hasLimitedAccessPlanType(patient.membershipPlanType) && !claimCode) {
          return this.router.parseUrl(this.links.limitedAccessAmazonRegistration);
        } else {
          return this.router.createUrlTree([this.links.registrationConsumer], {
            queryParams: { claim_code: claimCode },
          });
        }

        return false;
      }),
    );
  }

  // BEGIN todo: deduplicate between guard and resolver.
  // The check for claim code is nearly identical to LoginWithAmazonClaimCodeResolver.
  // Resolvers serve a different purpose in angular, so we don't want to import & invoke it
  // manually. But functionality is exactly the same, find a way to deduplicate.

  private findClaimCode(route: ActivatedRouteSnapshot) {
    const claimCodeFromQueryParams = route.queryParams[CLAIM_CODE_PARAM_KEY];
    const claimCodeFromStorage = this.fetchClaimCodeFromStorage();

    if (claimCodeFromQueryParams) {
      return observableOf(claimCodeFromQueryParams);
    }

    return this.dropOffClaimCodeGraphqlService
      .fetch()
      .pipe(map(result => result.data?.membership?.dropOffClaimCode || claimCodeFromStorage));
  }

  private fetchClaimCodeFromStorage(): string | null {
    if (!this.storage) {
      return null;
    }

    const claimCodeFromStorage = this.storage.getItem(CLAIM_CODE_PARAM_KEY);
    this.storage.removeItem(CLAIM_CODE_PARAM_KEY);
    return claimCodeFromStorage;
  }

  // END todo

  private isLoginWithAmazonRegistration(): Observable<boolean> {
    return this.loginWithAmazonRegistrationGraphqlService
      .fetch()
      .pipe(map(result => !!result.data?.patient?.isLwaRegUser));
  }

  private isLegacyOrEnterpriseRegistration(url: string): boolean {
    return this.isLegacyRegistration(url) || this.isEnterpriseReg(url);
  }

  private isOnboarding(url: string): boolean {
    return url === this.links.onboarding;
  }

  private isLegacyRegistration(url: string): boolean {
    return url === this.links.registrationRoot;
  }

  private isEnterpriseReg(url: string): boolean {
    return url.startsWith(this.links.registrationEnterprise);
  }

  private hasClaimedChartKey(): Observable<boolean | undefined> {
    return this.hasClaimedAccessKeyGraphqlService
      .fetch()
      .pipe(map(response => response?.data?.patient?.hasClaimedAccessKey));
  }
}
