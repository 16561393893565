import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import { Dict, Mixpanel } from 'mixpanel-browser';
import queryString from 'query-string';
import { asyncScheduler, bindCallback, Observable } from 'rxjs';

import { ElectronicHealthInformationExportFormat } from '@app/__generated__/graphql.types';
import { State } from '@app/app.reducer';
import { updateAnalyticsProperties } from '@app/core/analytics.actions';
import { AnalyticsState, getAnalyticsState } from '@app/core/analytics.reducer';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { FeatureFlagVariants } from '@app/core/feature-flags/feature-flags';
import { TrackMetricProps } from '@app/core/feature-flags/interfaces';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { Membership } from '@app/core/membership';
import {
  EMAIL_VERIFICATION_OUTREACH_EMAIL,
  EVENT_BOOK_VISIT_CLICKED,
  EVENT_PROVIDER_HUB_ALL_SET_CLICKED,
  EVENT_PROVIDER_HUB_APPT_CARD_CLICKED,
  FLOW_ACCOUNT_UPDATE,
  FLOW_APPOINTMENT_BOOKING,
  FLOW_LOGIN,
  FLOW_PCP_SELECTION,
  MODULE_ALL_TASKS_PAGE,
  MODULE_HOME_PAGE,
  MODULE_PCP_CARE_OPTIONS_PAGE,
  MODULE_PCP_OVERVIEW,
  MODULE_PHONE_DOB_SEX_PAGE,
  MODULE_SERVICE_AREA_PAGE,
  MP_EVENT_FEATURE_FLAG_EVALUATED,
  MP_EVENT_FRIENDS_AND_FAMILY_REFERRAL_STARTED,
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_REG_INPUT_ERROR_OCCURRED,
  MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_LINK_SELECTED,
  MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL,
  MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL_DISMISSED,
  MP_FLOW_REGISTRATION,
  MP_USER_PROFILE_MFA_PROPERTY_NAME,
  MYONE_HEADER_BANNER,
  REFERRAL_MODAL,
  SUBMODULE_CHILD_REGISTRATION_MODAL,
  TIMELINE_POST_CREATED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { MessagesPageLoadTrackingPayload } from '@app/message/message-summary/message-summary.component';
import { DocumentItem } from '@app/shared/document-item';

import { CommentItem } from '../message/comment-item';
import { MessageItem } from '../message/message-item';
import { UploadableFile } from '../message/uploadable-file';
import { Task } from '../shared/task';
import { BasicFollowUpOrderContent } from '../shared/task-content.types';
import { User } from '../shared/user';

export enum EVENT {
  PAGE_VIEWED = 'Page Viewed',
  UNIVERSAL_LINK_SELECTED = 'Universal Link Selected',
}

export enum FLOW {
  ACCESSING_PHR = 'Accessing PHR',
  CART_ABANDONMENT_EMAIL = 'Cart Abandonment Email',
  PATIENT_TASKS = 'Patient Tasks',
  SURVEYS = 'Surveys',
}

const MIXPANEL_FEATURE_FLAG_VALUE_MAP: Record<number, string> = {
  [-1]: 'OFF',
  [0]: 'ON CONTROL',
  [1]: 'ON VARIANT',
};

export const ANALYTICS_TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const MIXPANEL_TRACKED_FEATURE_FLAGS: string[] = [
  FeatureFlags.CONSUMER_REGISTRATION_EXPERIMENTATION_TEST,
  FeatureFlags.CONSUMER_REGISTRATION_PASSWORDLESS_FIRST_PAGE,
  FeatureFlags.CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL,
];

export interface TaskSpecialistData {
  specialist: string;
  specialistInfo?: boolean;
}

export interface MixpanelParamOverrides {
  flow?: string;
  module?: string;
  submodule?: string;
}

export interface AnalyticEvent {
  eventName: string;
  module?: string;
  submodule?: string;
  flow?: string;
  source?: string;
  properties?: Dict;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  static readonly connectedAccountsCommonProperties = {
    flow: FLOW_ACCOUNT_UPDATE,
    module: 'Apps and Devices Page',
  };

  private readonly mixpanel: Mixpanel;
  private currentUserId: number;
  protected module: string;
  protected defaultProperties: AnalyticsState;
  protected isWhitelist: boolean;
  protected defaultReferralProperties = {
    flow: 'Referral',
    module: REFERRAL_MODAL,
  };

  previousRoute: string;

  /**
   * @deprecated use trackWithDefaultProperties instead
   */
  protected track(eventName: string, properties?: Dict): Observable<any> {
    const track = bindCallback(this.mixpanel.track, undefined, asyncScheduler).call(
      this.mixpanel,
      eventName,
      properties,
    );

    track.subscribe();
    return track;
  }

  protected trackWithDefaultProperties(eventName: string, properties?: Dict): Observable<any> {
    const props = { ...this.defaultProperties, ...properties };

    const track = bindCallback(this.mixpanel.track, undefined, asyncScheduler).call(this.mixpanel, eventName, props);
    track.subscribe();

    return track;
  }

  /* To avoid circular dependencies, avoid injecting any services that themselves depend on the analytics service. */
  constructor(
    protected mixpanelService: MixpanelService,
    protected store: Store<State>,
    protected launchDarklyService: LaunchDarklyService,
    protected router?: Router,
  ) {
    this.mixpanel = mixpanelService.instance;
    this.launchDarklyService = launchDarklyService;
    this.store.pipe(select(getAnalyticsState)).subscribe({
      next: (state: AnalyticsState) => (this.defaultProperties = state),
    });

    this.trackCampaigns(document.location);

    if (this.router) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.previousRoute = this.router.url;
        }
      });
    }
  }

  trackHealthHistoryViewed(source: string, survey_system_name: string) {
    const properties = {
      flow: 'Survey',
      source: source,
      module: 'Survey Start Page',
      survey_system_name: survey_system_name,
    };

    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  setModule(module: string) {
    this.module = module;
  }

  trackWithLaunchDarkly(props: TrackMetricProps) {
    this.launchDarklyService.track(props);
  }

  trackFeatureFlags(featureFlags: string[]) {
    this.trackWithDefaultProperties('Feature Flags Synced', { feature_flags_on: featureFlags });
  }

  // getting to the task list
  patientTaskOverviewViewed(taskCount: number, source?: string) {
    const properties = {
      module: MODULE_ALL_TASKS_PAGE,
      flow: FLOW.PATIENT_TASKS,
      'Patient Task Count': taskCount,
      source: source || 'Homescreen Page',
    };
    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  // from task list to current task [Visit Summary Details Page | Email | All Tasks Page]
  patientTaskViewed(task: Task, source?: string) {
    const properties = {
      'Task Id': task.id,
      'Task Type': task.type,
      'Task State': task.state,
      'Task Scheduled At': task.scheduledAt,
      module: 'Task Page',
      flow: 'Patient Tasks',
      source: source,
    };

    return this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  // historical patient tasks from task list --> task [Visit Summary Details Page | Email | Historical Patient Tasks Page ]
  historicalTaskViewed(task: Task, source?: string) {
    const properties = {
      flow: FLOW.PATIENT_TASKS,
      module: ' Historical Patient Task Page',
      'Task Type': task.type,
      'Task State': task.state,
      'Task Id': task.id,
      source: source,
    };

    return this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  patientTaskDeclineTapped(task: Task) {
    return this.track('Patient Task Decline Tapped', this.taskCommonProperties(task));
  }

  patientTaskDeclined(task: Task) {
    return this.track('Patient Task Declined', this.taskCommonProperties(task));
  }

  patientTaskAlreadyDoneTapped(task: Task, property?: TaskSpecialistData) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
    }
    return this.track('Patient Task Already Done Tapped', taskProperties);
  }

  patientTaskAlreadyDone(task: Task, property?: TaskSpecialistData) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
      this.consultOrderSpecialistEventSelector(task, property);
    }
    if (task.type === 'basic_follow_up_order') {
      this.patientTaskSubmitted(task);
    } else {
      return this.track('Patient Task Already Done Confirmed', taskProperties);
    }
  }

  consultOrderSpecialistEventSelector(task: Task, property: TaskSpecialistData) {
    if (property.specialist === 'Elsewhere') {
      const specialistModule = property.specialistInfo
        ? 'Specialist Information Submitted'
        : 'Specialist Information Unknown Submitted';
      this.track(specialistModule, this.taskCommonProperties(task));
    }
  }

  patientTaskSubmitted(task: Task) {
    const taskProperties: Dict = this.taskCommonProperties;
    taskProperties['Task Feedback'] = (<BasicFollowUpOrderContent>task.content).feedback;
    return this.track('Patient Task Submitted', taskProperties);
  }

  referralPDFViewed(task: Task) {
    this.trackWithDefaultProperties('View Referral PDF Clicked', this.taskCommonProperties(task));
  }

  bloodPressureEntryViewed() {
    return this.track('Blood Pressure Entry Viewed');
  }

  bloodPressureSubmitted() {
    return this.track('Blood Pressure Submitted');
  }

  attachmentCreated(attachment: UploadableFile) {
    return this.track('Timeline Attachment Created', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentCreationFailed(attachment: UploadableFile) {
    return this.track('Timeline Attachment Creation Failed', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentDeleted(attachment: UploadableFile) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentViewed(document: DocumentItem) {
    return this.track('Timeline Attachment Viewed', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  attachmentDownloaded(document: DocumentItem) {
    return this.track('Timeline Attachment Downloaded', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  messagesPageLoad(trackingPayload: MessagesPageLoadTrackingPayload): void {
    const properties = {
      ...this.messagingCommonProperties(),
      Referrer: document.referrer,
      ...trackingPayload,
    };

    this.trackWithDefaultProperties('Timeline Post Overview Viewed', properties);
  }

  messageDetailPageLoad(post: MessageItem, source?: string) {
    const properties = {
      ...this.messagingCommonProperties({ post }),
      module: 'Message Details Page',
      source,
    };
    return this.trackWithDefaultProperties('Timeline Post Details Viewed', properties);
  }

  postStarted(post: MessageItem) {
    return this.trackWithDefaultProperties('Timeline Post Started', this.messagingCommonProperties({ post }));
  }

  commentStarted(comment: CommentItem) {
    return this.trackWithDefaultProperties('Timeline Comment Started', this.messagingCommonProperties({ comment }));
  }

  draftPostLoaded(post: MessageItem) {
    return this.trackWithDefaultProperties('Timeline Draft Post Loaded', this.messagingCommonProperties({ post }));
  }

  draftCommentLoaded(comment: CommentItem) {
    return this.trackWithDefaultProperties(
      'Timeline Draft Comment Loaded',
      this.messagingCommonProperties({ comment }),
    );
  }

  postCreated(post: MessageItem) {
    this.mixpanel.people.increment(TIMELINE_POST_CREATED);
    this.mixpanel.people.set({ 'Last Timeline Post Created': new Date(post.timestamp) });

    return this.trackWithDefaultProperties(TIMELINE_POST_CREATED, {
      ...this.messagingCommonProperties({ post }),
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
    });
  }

  postCreationFailed(post: MessageItem, error: HttpErrorResponse) {
    return this.trackWithDefaultProperties('Timeline Post Creation Failed', {
      ...this.messagingCommonProperties({ post }),
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  commentCreated(postId: number, comment: CommentItem) {
    this.mixpanel.people.increment('Timeline Comment Created');
    this.mixpanel.people.set({ 'Last Timeline Comment Created': new Date(comment.timestamp) });

    return this.trackWithDefaultProperties('Timeline Comment Created', {
      ...this.messagingCommonProperties({ comment }),
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
    });
  }

  commentCreationFailed(postId: number, comment: CommentItem, error: HttpErrorResponse) {
    return this.trackWithDefaultProperties('Timeline Comment Creation Failed', {
      ...this.messagingCommonProperties({ comment }),
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  postRecipientSelectorDropdownClicked(experiment_name: string, experiment_variation_name: FeatureFlagVariants) {
    const properties = {
      ...this.messagingCommonProperties(),
      experiment_name,
      experiment_variation_name,
    };

    this.trackWithDefaultProperties('Timeline Post Recipient Selector Dropdown Clicked', properties);
  }

  surveyLoaded(surveySystemName: string, source?: string) {
    return this.trackWithDefaultProperties('Survey Loaded', {
      survey_system_name: surveySystemName,
      flow: FLOW.SURVEYS,
      ...(source != null && { source }),
    });
  }

  surveyNextQuestion(surveySystemName: string, questionId: string) {
    return this.track('Load Next Question', {
      survey_system_name: surveySystemName,
      'Question Id': questionId,
    });
  }

  surveySubmitted(surveySystemName: string) {
    return this.track('Submit Survey', {
      survey_system_name: surveySystemName,
    });
  }

  appInstallBannerDismissed() {
    return this.track('App Install Banner Dismissed', {});
  }

  appInstallBannerAccepted() {
    return this.track('App Install Banner Accepted', {});
  }

  appStoreLinkClicked(location: string) {
    return this.track('App Store Link Clicked', {
      Location: location,
    });
  }

  trackHealthRecordLink(properties: Dict = {}) {
    return this.trackWithDefaultProperties('Health Record Clicked', {
      flow: 'Accessing PHR',
      ...properties,
    });
  }

  trackBookVisitLink(properties: Dict = {}) {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      ...properties,
    });
  }

  trackDirectSignupStarted(submodule: string): Observable<unknown> {
    return this.trackWithDefaultProperties('Direct Sign Up Started', {
      flow: 'Direct Sign Up',
      module: MODULE_HOME_PAGE,
      submodule,
      is_whitelist: this.isWhitelist,
      is_logged_in: true,
    });
  }

  trackReferralModalOpen(module: string, submodule: string): Observable<unknown> {
    return this.trackWithDefaultProperties(MP_EVENT_FRIENDS_AND_FAMILY_REFERRAL_STARTED, {
      flow: 'Referral',
      module,
      submodule,
    });
  }

  trackReferralBannerShown(properties: object) {
    this.track('Banner Displayed', {
      ...this.defaultProperties,
      ...properties,
      flow: 'Referral',
      module: this.module,
      submodule: MYONE_HEADER_BANNER,
    });
  }

  trackReferralSubmissionError(params: MixpanelParamOverrides) {
    this.trackWithDefaultProperties('Submit Error Encountered', {
      ...this.defaultReferralProperties,
      ...params,
    });
  }

  trackReferralLinkCopied(params: MixpanelParamOverrides) {
    this.trackWithDefaultProperties('Friends and Family Referral Link Copied', {
      ...this.defaultReferralProperties,
      ...params,
    });
  }

  trackInviteMoreFriends(params: MixpanelParamOverrides) {
    this.trackWithDefaultProperties('Invite More Friends and Family Clicked', {
      ...this.defaultReferralProperties,
      ...params,
    });
  }

  trackReferralModalClosed(params: MixpanelParamOverrides) {
    this.trackWithDefaultProperties('Friends and Family Referral Modal Closed', {
      ...this.defaultReferralProperties,
      ...params,
    });
  }

  trackReferralSubmitted(email: string, params: MixpanelParamOverrides) {
    this.trackWithDefaultProperties('Friends and Family Referral Submitted', {
      ...this.defaultReferralProperties,
      ...params,
      emails: [email],
      numEmails: 1,
    });
  }

  trackLoginPageView(queryParams: Params) {
    let source = '';
    if (this.isGoingToHomeFromEmailVerificationOutreach(queryParams)) {
      source = EMAIL_VERIFICATION_OUTREACH_EMAIL;
    } else if (['serviceAreaPageBanner', 'serviceAreaPageModal'].includes(queryParams.source)) {
      source = MODULE_SERVICE_AREA_PAGE;
    }

    const props = {
      source: source,
      flow: 'Login',
      module: 'Login Page',
      module_variant: queryParams.familyPromo === 'true' ? 'OM Kids Special 2021' : '',
    };

    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, props);
  }

  trackDirectLinksToLoggedInResources(attemptedPath: string) {
    if (attemptedPath.match('/membership/settings\\?gift_code=')) {
      this.trackGiftCodeRedemptionLoginPageView();
    }
  }

  pedsRegLinkClicked(properties: object) {
    return this.track('Pediatric Link Clicked', properties);
  }

  trackWithErrors(eventName: string, properties: object) {
    this.ensureRequiredPropertiesIncluded(properties);
    return this.trackWithDefaultProperties(eventName, properties);
  }

  identifyNewUser(user: User) {
    this.mixpanel.alias(`${user.id}`);
    this.mixpanel.identify(`${user.id}`);
  }

  trackHealthInformationReleaseAuthPageView() {
    this.track(MP_EVENT_PAGE_VIEWED, {
      ...this.defaultProperties,
      flow: 'Survey',
      is_whitelist: this.isWhitelist,
      module: 'COVID-19 Health Information Release Authorization Page',
    });
  }

  myProviderSidebarItemClicked() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Account Update',
      module: MODULE_PCP_OVERVIEW,
    });
  }

  myProviderSelectedExperiment(source: string) {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_PCP_SELECTION,
      module: MODULE_PCP_CARE_OPTIONS_PAGE,
      source: source,
    });
  }

  myProviderSelectedExperimentAppointmentClicked(submodule: string) {
    this.trackWithDefaultProperties(EVENT_PROVIDER_HUB_APPT_CARD_CLICKED, {
      flow: FLOW_PCP_SELECTION,
      module: MODULE_PCP_CARE_OPTIONS_PAGE,
      submodule: submodule,
    });
  }

  myProviderSelectedAllSetClicked() {
    this.trackWithDefaultProperties(EVENT_PROVIDER_HUB_ALL_SET_CLICKED, {
      flow: FLOW_PCP_SELECTION,
      module: MODULE_PCP_CARE_OPTIONS_PAGE,
    });
  }

  cartAbandonmentLinkClicked(_user: User) {
    this.trackWithDefaultProperties(EVENT.UNIVERSAL_LINK_SELECTED, { 'Universal Link': 'Cart Abandonment Email' });
  }

  resetMixpanelId() {
    this.mixpanel.reset();
  }

  identifyAndUpdateUser(user: User) {
    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          service_area: user.serviceArea?.name,
          ...(user.ageInYears && { member_age: user.ageInYears }),
        },
      }),
    );

    this.isWhitelist = user.whitelistedEmployee;

    if (this.currentUserId !== user.id) {
      this.mixpanel.identify(`${user.id}`);
      this.mixpanel.people.set({ Id: user.id });
      this.currentUserId = user.id;
    }
  }

  updateMFAProperties({ mfaEnabled }: { mfaEnabled: boolean }) {
    this.mixpanel.people.set({ [MP_USER_PROFILE_MFA_PROPERTY_NAME]: mfaEnabled });
  }

  updateMembershipProperties(membership: Membership) {
    if (!membership.omMembershipType) {
      throw new Error(
        `Unset membership type. Expected om_membership_type from membership but ${membership.omMembershipType} was found`,
      );
    }

    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          membership_status: membership.status,
          om_membership_type: membership.omMembershipType,
          ...(membership?.b2bCompany && { b2b_company_name: membership?.b2bCompany?.displayName }),
        },
      }),
    );
  }

  onFeatureFlagEvaluated(flag: string, value: any): void {
    if (MIXPANEL_TRACKED_FEATURE_FLAGS.includes(flag)) {
      this.trackWithDefaultProperties(MP_EVENT_FEATURE_FLAG_EVALUATED, {
        experiment_name: flag,
        experiment_variation_name: MIXPANEL_FEATURE_FLAG_VALUE_MAP[value] || value,
        flow: MP_FLOW_REGISTRATION,
      });
    }
  }

  trackPediatricRegRedirectModalShown(): void {
    this.trackWithDefaultProperties(MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL, {
      module: MODULE_PHONE_DOB_SEX_PAGE,
      submodule: SUBMODULE_CHILD_REGISTRATION_MODAL,
      flow: MP_FLOW_REGISTRATION,
    });
  }

  trackPediatricRegRedirectModalDismissed(): void {
    this.trackWithDefaultProperties(MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL_DISMISSED, {
      module: MODULE_PHONE_DOB_SEX_PAGE,
      submodule: SUBMODULE_CHILD_REGISTRATION_MODAL,
      flow: MP_FLOW_REGISTRATION,
    });
  }

  trackPediatricRegRedirectCtaSelected(): void {
    this.trackWithDefaultProperties(MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_LINK_SELECTED, {
      module: SUBMODULE_CHILD_REGISTRATION_MODAL,
      flow: MP_FLOW_REGISTRATION,
    });
  }

  trackPediatricRegRedirectLinkSelected(): void {
    this.trackWithDefaultProperties(MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_LINK_SELECTED, {
      module: MODULE_PHONE_DOB_SEX_PAGE,
      flow: MP_FLOW_REGISTRATION,
    });
  }

  trackAdultConsumerRegDobError(): void {
    this.trackWithDefaultProperties(MP_EVENT_REG_INPUT_ERROR_OCCURRED, {
      module: MODULE_PHONE_DOB_SEX_PAGE,
      flow: MP_FLOW_REGISTRATION,
      error: 'PediatricAge',
      form_field: 'Birthday',
    });
  }

  trackEventWithDefaultProperties({ eventName, module, submodule, flow, source, properties }: AnalyticEvent) {
    return this.trackWithDefaultProperties(eventName, { module, submodule, flow, source, ...properties });
  }

  private isGoingToHomeFromEmailVerificationOutreach(queryParams: Params): boolean {
    return queryParams.returnUrl === `/?source=emailVerificationOutreach`;
  }

  private trackGiftCodeRedemptionLoginPageView() {
    this.track(MP_EVENT_PAGE_VIEWED, {
      ...this.defaultProperties,
      flow: 'Post Gift Registration',
      module: 'Gift Login Page',
    });
  }

  private ensureRequiredPropertiesIncluded(properties: Dict) {
    if (!properties.flow) {
      throw new Error('"flow" is a required property');
    }
    if (!properties.module) {
      throw new Error('"module" is a required property');
    }
  }

  private taskCommonProperties(task: Task): Dict {
    return {
      patient_task_id: task.id,
      'Task Id': task.id,
      patient_task_type: task.type,
      'Task Type': task.type,
      'Task State': task.state,
      'Task Scheduled At': task.scheduledAt,
      'Task Provider Id': task.provider.id,
      module: this.module,
      flow: 'Patient Tasks',
    };
  }

  private messagingCommonProperties({ post, comment }: { post?: MessageItem; comment?: CommentItem } = {}) {
    const properties: Record<string, string | number> = {
      flow: 'Messages Overview',
      module: this.module,
    };

    if (post) {
      properties.recipient = post.recipient;
      properties['Post Id'] = post.id;
    }

    if (comment) {
      properties.recipient = comment.originalPost.recipient;
      properties['Post Id'] = comment.originalPost.id;
      properties['Comment Id'] = comment.id;
    }

    return properties;
  }

  private trackCampaigns(location: Location) {
    const keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
    const lastValues: Dict = {};
    const firstValues: Dict = {};
    const params = queryString.parse(location.search, { decode: true });
    for (const keyword of Object.keys(keywords)) {
      const value = params[keyword];
      if (value) {
        lastValues[keyword + ' [last touch]'] = value;
        firstValues[keyword + ' [first touch]'] = value;
      }
    }
    this.mixpanel.people.set_once(firstValues);
    this.mixpanel.people.set(lastValues);
    this.mixpanel.register(lastValues);
  }

  trackConnectedAccountsPageViewed(isFitbitConnected: boolean) {
    let properties = cloneDeep(AnalyticsService.connectedAccountsCommonProperties);
    if (isFitbitConnected) {
      properties = merge(properties, { module_variant: 'Fitbit Connected State' });
    }
    this.trackWithDefaultProperties('Page Viewed', properties);
  }

  trackConnectYourFitbitClicked() {
    return this.trackWithDefaultProperties(
      'Connect Your Fitbit Clicked',
      AnalyticsService.connectedAccountsCommonProperties,
    );
  }

  trackFitbitSuccessfullyConnected() {
    this.trackWithDefaultProperties(
      'Fitbit Successfully Connected',
      AnalyticsService.connectedAccountsCommonProperties,
    );
  }

  trackDownloadVaccineRecordsClicked(
    user: User,
    membership: Membership,
    medical_record_type = ElectronicHealthInformationExportFormat.Pdf,
  ): Observable<void> {
    return this.trackWithDefaultProperties('Download Vaccine Record Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      module: 'Request Records Page',
      service_area: user.serviceArea.name,
      om_membership_type: membership.omMembershipType,
      membership_status: membership.status,
      medical_record_type: medical_record_type,
    });
  }

  trackGuestPageViewed() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_LOGIN,
      module: 'Limited Access Page',
      om_membership_type: 'Limited Access',
    });
  }

  trackBecomeAMemberClicked() {
    this.trackWithDefaultProperties('Become a member clicked', {
      ...this.messagingCommonProperties(),
    });
  }

  setYearOfBirthToMixpanelUser(year: number) {
    this.mixpanel.people.set({ year_of_birth: year });
  }

  setFlowVersionForMixpanel(flowVersion: string) {
    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          flow_version: flowVersion,
        },
      }),
    );
  }
}
